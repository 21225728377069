<template>
  <v-container fluid>
    <v-form>
      <v-row>
        <v-col cols="8">
          <base-material-card
            color="primary"
            icon="mdi-wifi-marker"
            inline
            class="px-5 py-3"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">
                Adicionar Novo Beacon
              </div>
            </template>
            <v-row class="mt-5">
              <v-col cols="12">
                <input-mac-address
                  label="MAC Address do beacon"
                  @macAddressInput="(input) => (macAddress = input)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="entidadeBdgd"
                  :items="entidadesBdgd"
                  :loading="loadingEquipamentos"
                  label="Selecione o equipamento a ser vinculado ao beacon"
                  item-value="entidade"
                  item-text="entidade"
                  prepend-icon="mdi-connection"
                  class="mb-0 pb-0"
                  no-data-text="Nenhum equipamento encontrado na subestação selecionada"
                />
              </v-col>
            </v-row>
            <v-progress-circular
              v-if="loadingDetalhesEquipamentos"
              size="15"
              indeterminate
              color="rgba(0, 0, 0, 0.87)"
            />
            <v-row v-else>
              <v-col cols="12">
                <general-progress-bars
                  class="mt-5 mb-5"
                  :items="downloadProgressData"
                  prefixText="Baixando equipamentos ..."
                />
                <v-data-table
                  v-model="equipamentoSelecionado"
                  v-if="detalhesEquipamentos.length > 0"
                  class="mb-5"
                  :headers="[
                    {
                      text: 'COD_ID',
                      value: 'cod_id'
                    },
                    {
                      text: 'IDUC',
                      value: 'iduc'
                    },
                    {
                      text: 'ODI',
                      value: 'odi'
                    },
                    {
                      text: 'TI',
                      value: 'ti'
                    },
                    {
                      text: 'CM',
                      value: 'cm'
                    },
                    {
                      text: 'TUC',
                      value: 'tuc'
                    },
                    {
                      text: 'A1',
                      value: 'a1'
                    },
                    {
                      text: 'A2',
                      value: 'a2'
                    },
                    {
                      text: 'A3',
                      value: 'a3'
                    },
                    {
                      text: 'A4',
                      value: 'a4'
                    },
                    {
                      text: 'A5',
                      value: 'a5'
                    },
                    {
                      text: 'A6',
                      value: 'a6'
                    },
                    {
                      text: 'UAR',
                      value: 'uar'
                    },
                    {
                      text: '',
                      value: 'data-table-expand'
                    }
                  ]"
                  :items="detalhesEquipamentos"
                  :search.sync="search"
                  item-key="cod_id"
                  single-select
                  show-select
                  show-expand
                  no-results-text="Nenhum equipamento encontrado"
                >
                  <template v-slot:header.data-table-select>
                    <v-btn
                      class="px-1"
                      min-width="0"
                      fab
                      icon
                      x-small
                      title="Baixar equipamentos"
                      @click="baixarEquipamentos"
                      :loading="loadingBaixarEquipamentos"
                    >
                      <v-icon small> mdi-download </v-icon>
                    </v-btn>
                  </template>
                  <template
                    v-slot:top
                    v-if="detalhesEquipamentos.length >= 10"
                  >
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Procurar"
                      hide-details
                      single-line
                      class="mx-4 mb-3"
                    />
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-simple-table>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td><strong>TI:</strong></td>
                              <td>{{ item.ti }}</td>
                              <td>{{ item.descricao_ti }}</td>
                            </tr>
                            <tr>
                              <td><strong>CM1:</strong></td>
                              <td>{{ item.cm1 }}</td>
                              <td>{{ item.descricao_cm1 }}</td>
                            </tr>
                            <tr>
                              <td><strong>CM2:</strong></td>
                              <td>{{ item.cm2 }}</td>
                              <td>{{ item.descricao_cm2 }}</td>
                            </tr>
                            <tr>
                              <td><strong>CM3:</strong></td>
                              <td>{{ item.cm3 }}</td>
                              <td>{{ item.descricao_cm3 }}</td>
                            </tr>
                            <tr>
                              <td><strong>TUC:</strong></td>
                              <td>{{ item.tuc }}</td>
                              <td>{{ item.descricao_tuc }}</td>
                            </tr>
                            <tr>
                              <td><strong>A1:</strong></td>
                              <td>{{ item.a1 }}</td>
                              <td>{{ item.descricao_a1 }}</td>
                            </tr>
                            <tr>
                              <td><strong>A2:</strong></td>
                              <td>{{ item.a2 }}</td>
                              <td>{{ item.descricao_a2 }}</td>
                            </tr>
                            <tr>
                              <td><strong>A3:</strong></td>
                              <td>{{ item.a3 }}</td>
                              <td>{{ item.descricao_a3 }}</td>
                            </tr>
                            <tr>
                              <td><strong>A4:</strong></td>
                              <td>{{ item.a4 }}</td>
                              <td>{{ item.descricao_a4 }}</td>
                            </tr>
                            <tr>
                              <td><strong>A5:</strong></td>
                              <td>{{ item.a5 }}</td>
                              <td>{{ item.descricao_a5 }}</td>
                            </tr>
                            <tr>
                              <td><strong>A6:</strong></td>
                              <td>{{ item.a6 }}</td>
                              <td>{{ item.descricao_a6 }}</td>
                            </tr>
                            <tr>
                              <td><strong>UAR:</strong></td>
                              <td>{{ item.uar }}</td>
                              <td>{{ item.descricao_uar }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </td>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
        <v-col cols="4">
          <base-material-card
            color="primary"
            icon="mdi-home-lightning-bolt"
            inline
            class="px-5 py-3"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">
                Subestação onde o beacon está localizado
              </div>
            </template>
            <v-row class="mt-5">
              <v-col cols="12">
                <select-subestacao
                  exibe-mapa
                  :bdgdVersao="bdgdVersao"
                  @subSelected="(sub) => (subestacao = sub)"
                />
              </v-col>
            </v-row>
          </base-material-card>
          <v-row class="mt-8">
            <v-col cols="12">
              <v-btn
                color="primary"
                min-width="100"
                style="float: right"
                class="mt-n5 mr-0"
                @click="salvarBeacon"
                :disabled="!podeSalvarBeacon"
                :loading="loading"
              >
                Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import SubestacoesService from '@/services/SubestacoesService';
import BeaconsService from '@/services/monitoramento-em-tempo-real/BeaconsService';
import InputMacAddress from '@/components/general/InputMacAddress';
import SelectSubestacao from '@/components/general/SelectSubestacao';
import GeneralProgressBars from '@/components/general/GeneralProgressBars';
import routes from '@/store/modules/routes';

export default {
  components: {
    InputMacAddress,
    SelectSubestacao,
    GeneralProgressBars
  },
  data: () => ({
    loading: false,
    loadingEquipamentos: false,
    loadingDetalhesEquipamentos: false,
    loadingBaixarEquipamentos: false,
    downloadProgressData: {},
    search: null,
    macAddress: null,
    subestacao: null,
    entidadeBdgd: null,
    entidadesBdgd: [],
    equipamentoSelecionado: null,
    detalhesEquipamentos: []
  }),
  mounted() {
    this.getEquipamentosNaSubestacao();
  },
  computed: {
    bdgdVersao() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    },
    podeSalvarBeacon() {
      return (
        this.macAddress !== null &&
        this.macAddress.length === 17 &&
        this.subestacao !== null &&
        this.entidadesBdgd.length > 0 &&
        this.entidadeBdgd !== null &&
        this.equipamentoSelecionado &&
        this.equipamentoSelecionado[0] &&
        this.equipamentoSelecionado[0].cod_id !== null
      );
    }
  },
  methods: {
    getEquipamentosNaSubestacao() {
      if (!(this.bdgdVersao && this.subestacao)) return;
      this.loadingEquipamentos = true;
      SubestacoesService.getEquipamentosNaSubestacao(
        this.bdgdVersao.id,
        this.subestacao.cod_id
      )
        .then(({ data }) => {
          this.entidadeBdgd = null;
          this.entidadesBdgd = data;
          this.detalhesEquipamentos = [];
          this.equipamentoSelecionado = null;
        })
        .finally(() => (this.loadingEquipamentos = false));
    },
    getDetalhesEquipamentosInstaladosNaSubestacao() {
      if (!(this.bdgdVersao && this.subestacao && this.entidadeBdgd)) return;
      this.loadingDetalhesEquipamentos = true;
      this.equipamentoSelecionado = null;
      SubestacoesService.getDetalhesEquipamentosInstaladosNaSubestacao(
        this.bdgdVersao.id,
        this.subestacao.cod_id,
        this.entidadeBdgd
      )
        .then(({ data }) => (this.detalhesEquipamentos = data))
        .finally(() => (this.loadingDetalhesEquipamentos = false));
    },
    baixarEquipamentos() {
      if (!(this.bdgdVersao && this.subestacao && this.entidadeBdgd)) return;
      const id = 0;
      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });
      SubestacoesService.baixarDetalhesEquipamentosInstaladosNaSubestacao(
        this.bdgdVersao.id,
        this.subestacao.cod_id,
        this.entidadeBdgd,
        {
          timeout: 60 * 60 * 1000,
          onDownloadProgress: (progressEvent) => {
            let progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            let isComputable = progressEvent.lengthComputable;
            this.$set(this.downloadProgressData, id, {
              progress,
              isComputable,
              id
            });
          }
        }
      )
        .then((res) => {
          const entidadeBdgd = this.entidadeBdgd.toLowerCase();
          const subestacao = this.subestacao.cod_id;
          const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
          const nomeArquivo = `${entidadeBdgd}-subestacao-${subestacao}-${timestamp}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar os equipamentos da subestação selecionada.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    },
    salvarBeacon() {
      this.loading = true;
      BeaconsService.salvaBeacon({
        mac_address: this.macAddress,
        sub: this.subestacao.cod_id,
        entidade: this.entidadeBdgd,
        cod_id: this.equipamentoSelecionado[0].cod_id
      })
        .then(() => {
          this.$toast.success(
            'Beacon associado ao equipamento com sucesso.',
            '',
            {
              position: 'topRight'
            }
          );
          this.$router.replace({
            name: routes.state.appRoutes['BEACONS_X_EQUIPAMENTOS_LISTAR']
          });
        })
        .catch((error) => {
          console.log('Error:', error);
          this.$toast.error(
            'Erro ao salvar o vínculo do beacon com o equipamento.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    }
  },
  watch: {
    bdgdVersao() {
      this.getEquipamentosNaSubestacao();
    },
    subestacao() {
      this.getEquipamentosNaSubestacao();
    },
    entidadeBdgd() {
      this.getDetalhesEquipamentosInstaladosNaSubestacao();
    }
  }
};
</script>
