<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
  >
    <v-text-field
      v-model="formatedMacAddress"
      :error-messages="errors"
      v-mask="'XX:XX:XX:XX:XX:XX'"
      :label="label"
      prepend-icon="mdi-expansion-card-variant"
      class="pt-0"
      @input="handleMacAddressInput"
      placeholder="00:00:00:00:00:00"
      clearable
    />
  </validation-provider>
</template>

<script>
const MAC_REGEX = /^[0-9A-F]{1,2}([\.:-])[0-9A-F]{1,2}(?:\1[0-9A-F]{1,2}){4}$/;
export default {
  name: 'InputMacAddress',
  props: {
    label: {
      type: String,
      default: 'MAC Address'
    },
    selectedMacAddress: {
      type: String,
      default: null
    },
    rules: {
      type: Object,
      default() {
        return {
          min: 17,
          regex: MAC_REGEX
        };
      }
    }
  },
  data() {
    return {
      formatedMacAddress: null
    };
  },
  mounted() {
    if (this.selectedMacAddress) {
      this.formatedMacAddress = this.selectedMacAddress;
    }
  },
  methods: {
    handleMacAddressInput() {
      if (!MAC_REGEX.test(this.formatedMacAddress)) return false;
      this.$emit('macAddressInput', this.formatedMacAddress);
    }
  }
};
</script>
